import { useEventListener } from '@vueuse/core';
import { useSubscribeModalStore } from '@/js/store/subscribe/SubscribeModalStore';

/*
    Usage:
        Add '.open-newsletter' class to element.
    Example:
        <div class="open-newsletter"></div>
*/

export const openNewsletterModal = () => {
    const subscribeModalStore = useSubscribeModalStore();

    subscribeModalStore.setSubscribeModal(true, true);
};

const addClickEvents = () => {
    const triggerElements = document.querySelectorAll(' .open-newsletter');

    triggerElements.forEach((element) => {
        useEventListener(element, 'click', openNewsletterModal);
    });
};

useEventListener(document, 'DOMContentLoaded', addClickEvents);
